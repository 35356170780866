'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var base = {
  animationTimingFunction: (0, _utils.cubicBezier)(0.2125, 0.610, 0.355, 1.000),
  transform: (0, _utils.translate3d)(0, 0, 0)
};


var bounce = {
  '0%': base,
  '20%': base,
  '40%': {
    animationTimingFunction: (0, _utils.cubicBezier)(0.755, 0.050, 0.855, 0.060),
    transform: (0, _utils.translate3d)(0, '-30px', 0)
  },
  '43%': {
    animationTimingFunction: (0, _utils.cubicBezier)(0.755, 0.050, 0.855, 0.060),
    transform: (0, _utils.translate3d)(0, '-30px', 0)
  },
  '53%': base,
  '70%': {
    animationTimingFunction: (0, _utils.cubicBezier)(0.755, 0.050, 0.855, 0.060),
    transform: (0, _utils.translate3d)(0, '-50px', 0)
  },
  '80%': base,
  '90%': {
    transform: (0, _utils.translate3d)(0, '-4px', 0)
  },
  '100%': base
};

exports.default = bounce;