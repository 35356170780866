'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var scaleAndTranslate = (0, _utils.compose)(_utils.scale3d, _utils.translate3d);


var zoomOutDown = {
  '40%': {
    opacity: 1,
    transform: scaleAndTranslate([0.475, 0.475, 0.475], [0, '-60px', 0]),
    animationTimingFunction: (0, _utils.cubicBezier)(0.550, 0.055, 0.675, 0.190)
  },
  to: {
    opacity: 0,
    transform: scaleAndTranslate([0.1, 0.1, 0.1], [0, '2000px', 0]),
    transformOrigin: 'center bottom',
    animationTimingFunction: (0, _utils.cubicBezier)(0.175, 0.885, 0.320, 1)
  }
};

exports.default = zoomOutDown;