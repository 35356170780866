"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var shakes = {
  little: {
    h: 2,
    v: 2,
    r: 2
  },
  slow: {
    h: 20,
    v: 20,
    r: 7,
    dur: 5000
  },
  hard: {
    h: 20,
    v: 20,
    r: 7
  },
  horizontal: {
    h: 20,
    v: 0,
    r: 0
  },
  vertical: {
    h: 0,
    v: 20,
    r: 0
  },
  rotate: {
    h: 0,
    v: 0,
    r: 15
  },
  crazy: {
    h: 40,
    v: 40,
    r: 20,
    dur: 100,
    "int": .1
  }
};
var _default = shakes;
exports["default"] = _default;