'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var hingeUp = {
  transform: (0, _utils.rotate3d)(0, 0, 1, 80),
  transformOrigin: 'top left',
  animationTimingFunction: 'ease-in-out'
};


var hingeDown = {
  transform: (0, _utils.rotate3d)(0, 0, 1, 60),
  transformOrigin: 'top left',
  animationTimingFunction: 'ease-in-out',
  opacity: 1
};

var hinge = {
  '0%': {
    transformOrigin: 'top left',
    animationTimingFunction: 'ease-in-out'
  },
  '20%': hingeUp,
  '40%': hingeDown,
  '60%': hingeUp,
  '80%': hingeDown,
  to: {
    transform: (0, _utils.translate3d)(0, '700px', 0),
    opacity: 0
  }
};

exports.default = hinge;