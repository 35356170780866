'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var rotateOutUpLeft = {
  from: {
    transformOrigin: 'left bottom',
    opacity: 1
  },
  to: {
    transformOrigin: 'left bottom',
    transform: (0, _utils.rotate3d)(0, 0, 1, -45),
    opacity: 0
  }
};
exports.default = rotateOutUpLeft;