'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _utils = require('./utils');

var timing = {
  animationTimingFunction: (0, _utils.cubicBezier)(0.215, 0.610, 0.355, 1.000)
};

var bounceInLeft = {
  from: timing,
  '0%': {
    opacity: 0,
    transform: (0, _utils.translate3d)('-3000px', 0, 0)
  },
  '60%': _extends({}, timing, {
    opacity: 1,
    transform: (0, _utils.translate3d)('25px', 0, 0)
  }),
  '75%': _extends({}, timing, {
    transform: (0, _utils.translate3d)('-10px', 0, 0)
  }),
  '90%': _extends({}, timing, {
    transform: (0, _utils.translate3d)('5px', 0, 0)
  }),
  to: _extends({}, timing, {
    transform: 'none'
  })
};

exports.default = bounceInLeft;