'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = undefined;

var _utils = require('./utils');

var swing = {
  '20%': {
    transform: (0, _utils.rotate3d)(0, 0, 1, 15)
  },
  '40%': {
    transform: (0, _utils.rotate3d)(0, 0, 1, -10)
  },
  '60%': {
    transform: (0, _utils.rotate3d)(0, 0, 1, 5)
  },
  '80%': {
    transform: (0, _utils.rotate3d)(0, 0, 1, -5)
  },
  to: {
    transform: (0, _utils.rotate3d)(0, 0, 1, 15)
  }
};
var styles = exports.styles = {
  transformOrigin: 'top center'
};

exports.default = swing;