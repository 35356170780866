'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _utils = require('./utils');

var timing = {
  animationTimingFunction: (0, _utils.cubicBezier)(0.215, 0.610, 0.355, 1.000)
};

var bounceIn = {
  from: timing,
  '0%': {
    opacity: 0,
    transform: (0, _utils.scale3d)(0.3, 0.3, 0.3)
  },
  '20%': _extends({}, timing, {
    transform: (0, _utils.scale3d)(1.1, 1.1, 1.1)
  }),
  '40%': _extends({}, timing, {
    transform: (0, _utils.scale3d)(0.9, 0.9, 0.9)
  }),
  '60%': _extends({}, timing, {
    opacity: 1,
    transform: (0, _utils.scale3d)(1.03, 1.03, 1.03)
  }),
  '80%': _extends({}, timing, {
    transform: (0, _utils.scale3d)(0.97, 0.97, 0.97)
  }),
  to: _extends({}, timing, {
    opacity: 1,
    transform: (0, _utils.scale3d)(1, 1, 1)
  })
};

exports.default = bounceIn;