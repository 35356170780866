'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var scaleAndRotate = (0, _utils.compose)(_utils.scale3d, _utils.rotate3d);


var noScale = {
  transform: (0, _utils.scale3d)(1, 1, 1)
};

var scaleDownNegativeAngle = {
  transform: scaleAndRotate([0.9, 0.9, 0.9], [0, 0, 1, -3])
};

var scaleUpPositiveAngle = {
  transform: scaleAndRotate([1.1, 1.1, 1.1], [0, 0, 1, 3])
};

var scaleUpNegativeAngle = {
  transform: scaleAndRotate([1.1, 1.1, 1.1], [0, 0, 1, -3])
};

var tada = {
  from: noScale,
  '10%': scaleDownNegativeAngle,
  '20%': scaleDownNegativeAngle,
  '30%': scaleUpPositiveAngle,
  '40%': scaleUpNegativeAngle,
  '50%': scaleUpPositiveAngle,
  '60%': scaleUpNegativeAngle,
  '70%': scaleUpPositiveAngle,
  '80%': scaleUpNegativeAngle,
  '90%': scaleUpPositiveAngle,
  to: noScale
};

exports.default = tada;