'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var bounceOut = {
  '20%': {
    transform: (0, _utils.scale3d)(0.9, 0.9, 0.9)
  },
  '50%': {
    transform: (0, _utils.scale3d)(1.1, 1.1, 1.1)
  },
  '55%': {
    transform: (0, _utils.scale3d)(1.1, 1.1, 1.1)
  },
  to: {
    opacity: 0,
    transform: (0, _utils.scale3d)(0.3, 0.3, 0.3)
  }
};
exports.default = bounceOut;