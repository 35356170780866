'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


var visible = {
  opacity: 1
};


var invisible = {
  opacity: 0
};

var flash = {
  from: visible,
  '25%': invisible,
  '50%': visible,
  '75%': invisible,
  to: visible
};

exports.default = flash;