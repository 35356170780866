'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var bounceOutDown = {
  '20%': {
    transform: (0, _utils.translate3d)(0, '10px', 0)
  },
  '40%': {
    transform: (0, _utils.translate3d)(0, '-20px', 0)
  },
  '45%': {
    transform: (0, _utils.translate3d)(0, '-20px', 0)
  },
  to: {
    opacity: 0,
    transform: (0, _utils.translate3d)(0, '2000px', 0)
  }
};
exports.default = bounceOutDown;