'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var translateAndRotate = (0, _utils.compose)(_utils.translate3d, _utils.rotate3d);


var rollIn = {
  from: {
    opacity: 0,
    transform: translateAndRotate(['-100%', 0, 0], [0, 0, 1, -120])
  },
  to: {
    opacity: 1,
    transform: 'none'
  }
};

exports.default = rollIn;