'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


var fadeIn = {
  from: {
    opacity: 0
  },
  to: {
    opacity: 1
  }
};
exports.default = fadeIn;