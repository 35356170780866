'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = merge;


// The default allowed delta for keyframe distance
var keyframeDistance = 10;

var defaultNormalizedFrames = {
  'from': 'from',
  '0%': 'from',
  'to': 'to',
  '100%': 'to'
};

/**
 * Takes an array of strings representing transform values and
 * merges them. Ignores duplicates and 'none'.
 * @param {Array} transforms Array<string>
 * @returns {String} merged
 * @private
 * @example
 * mergeTransforms([
 *   'translateX(10px)',
 *   'rotateX(120deg)',
 *   'translateX(10px)',
 *   'none',
 * ])
 * // -> 'translateX(10px) rotateX(120deg)'
 *
 */
var mergeTransforms = function mergeTransforms(transforms) {
  var filtered = transforms.filter(function (transform, i) {
    return transform !== 'none' && transforms.indexOf(transform) === i;
  });
  return filtered.join(' ');
};

/**
 * Returns whichever value is actually defined
 * @param {String|Number} primary CSSValue
 * @param {String|Number} secondary CSSValue
 * @returns {String|Number} defined CSSValue
 * @private
 */
var getDefined = function getDefined(primary, secondary) {
  return typeof primary !== 'undefined' ? primary : secondary;
};

/**
 * Takes a source animation and the current cache, populating the
 * cache with the normalized keyframes and returning a copy of the
 * source animation with the normalized keyframes as well.
 *
 * It uses keyframeDistance to determine how much it should normalize
 * frames.
 * @param {Object} source Animation
 * @param {Object} cache FrameMap
 * @returns {Object} Animation
 * @private
 */
var normalizeFrames = function normalizeFrames(source, cache) {
  var normalized = {};
  for (var frame in source) {
    var normalizedFrame = defaultNormalizedFrames[frame] || Math.round(parseFloat(frame) / keyframeDistance) * keyframeDistance + '%';
    normalized[normalizedFrame] = source[frame];
    cache[normalizedFrame] = normalizedFrame;
  }
  return normalized;
};

var mergeFrames = function mergeFrames(primaryFrame, secondaryFrame, target) {
  // Walk through all properties in the primary frame
  for (var propertyName in primaryFrame) {
    // Transform is special cased, as we want to combine both
    // transforms when posssible.
    if (propertyName === 'transform') {
      // But we dont need to do anything if theres no other
      // transform to merge.
      if (secondaryFrame[propertyName]) {
        var newTransform = mergeTransforms([primaryFrame[propertyName], secondaryFrame[propertyName]]);
        // We make the assumption that animations use 'transform: none'
        // to terminate the keyframe. If we're combining two animations
        // that may terminate at separte frames, its safest to just
        // ignore this.
        if (newTransform !== 'none') {
          target[propertyName] = newTransform;
        }
      } else {
        var propertyValue = getDefined(primaryFrame[propertyName], secondaryFrame[propertyName]);
        target[propertyName] = propertyValue;
      }
    } else {
      // Use a typeof check so we don't ignore falsy values like 0.
      var _propertyValue = getDefined(primaryFrame[propertyName], secondaryFrame[propertyName]);
      target[propertyName] = _propertyValue;
    }
  }
  // Walk through all properties in the secondary frame.
  // We should be able to assume that any property that
  // needed to be merged has already been merged when we
  // walked the primary frame.
  for (var _propertyName in secondaryFrame) {
    var _propertyValue2 = secondaryFrame[_propertyName];
    // Again, ignore 'transform: none'
    if (_propertyName === 'transform' && _propertyValue2 === 'none') {
      continue;
    }
    target[_propertyName] = target[_propertyName] || _propertyValue2;
  }
};

var populateDefinedFrame = function populateDefinedFrame(primaryFrame, secondaryFrame) {
  var definedFrame = primaryFrame || secondaryFrame;
  var target = {};
  for (var propertyName in definedFrame) {
    var propertyValue = definedFrame[propertyName];
    // Again, ignore 'transform: none'
    if (propertyName === 'transform' && propertyValue === 'none') {
      continue;
    }
    target[propertyName] = propertyValue;
  }
  // Only define a frame if there are actual styles to apply
  if (Object.keys(target).length) {
    return target;
  }
  return null;
};

/**
 * Merge lets you take two Animations and merge them together. It
 * iterates through each animation and merges each keyframe. It
 * special cases the `transform` property and uses string interop.
 * to merge the two transforms.
 *
 * This is *at your own risk* and will not work with animations
 * that are clearly opposites (fadeIn and fadeOut).
 *
 * @param {Object} primary Animation
 * @param {Object} secondary Animation
 * @returns {Object} merged Animation
 * @example
 * import { merge, tada, flip } from 'react-animations';
 * const tadaFlip = merge(tada, flip);
 */
function merge(primary, secondary) {
  // A map used to track the normalized frame value in cases where
  // two animations contain frames that appear closely, but not exactly
  var normalizedFrames = {};

  // We merge each frame into a new object and return it
  var merged = {};

  var normalizedPrimary = normalizeFrames(primary, normalizedFrames);

  var normalizedSecondary = normalizeFrames(secondary, normalizedFrames);

  // Iterate all normalized frames
  for (var frame in normalizedFrames) {
    var primaryFrame = normalizedPrimary[frame];
    var secondaryFrame = normalizedSecondary[frame];
    // Create a new frame object if it doesn't exist.
    var target = merged[frame] || (merged[frame] = {});

    // If both aniatmions define this frame, merge them carefully
    if (primaryFrame && secondaryFrame) {
      mergeFrames(primaryFrame, secondaryFrame, target);
    } else {
      // Otherwise find the defined frime and populate all properties\
      // except for "transform" when the value is none.
      var keyframe = populateDefinedFrame(primaryFrame, secondaryFrame);
      if (keyframe) {
        merged[frame] = keyframe;
      }
    }
  }
  return merged;
}