'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var perspectiveAndRotate = (0, _utils.compose)(_utils.perspective, _utils.rotate3d);


var perspectiveAndScale = (0, _utils.compose)(_utils.perspective, _utils.scale3d);

var perspectiveTranslateRotate = (0, _utils.compose)(_utils.perspective, _utils.translate3d, _utils.rotate3d);

var flip = {
  from: {
    animationTimingFunction: 'ease-out',
    transform: perspectiveAndRotate('400px', [0, 1, 0, -360])
  },
  '40%': {
    animationTimingFunction: 'ease-out',
    transform: perspectiveTranslateRotate('400px', [0, 0, '150px'], [0, 1, 0, -190])
  },
  '50%': {
    animationTimingFunction: 'ease-in',
    transform: perspectiveTranslateRotate('400px', [0, 0, '150px'], [0, 1, 0, -170])
  },
  '80%': {
    animationTimingFunction: 'ease-in',
    transform: perspectiveAndScale('400px', [0.95, 0.95, 0.95])
  },
  to: {
    animationTimingFunction: 'ease-in',
    transform: (0, _utils.perspective)('400px')
  }
};

exports.default = flip;