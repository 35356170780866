'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zoomOutUp = exports.zoomOutRight = exports.zoomOutLeft = exports.zoomOutDown = exports.zoomOut = exports.zoomInUp = exports.zoomInRight = exports.zoomInLeft = exports.zoomInDown = exports.zoomIn = exports.rollOut = exports.rollIn = exports.hinge = exports.slideOutUp = exports.slideOutRight = exports.slideOutLeft = exports.slideOutDown = exports.slideInUp = exports.slideInRight = exports.slideInLeft = exports.slideInDown = exports.rotateOutUpRight = exports.rotateOutUpLeft = exports.rotateOutDownRight = exports.rotateOutDownLeft = exports.rotateOut = exports.rotateInUpRight = exports.rotateInUpLeft = exports.rotateInDownRight = exports.rotateInDownLeft = exports.rotateIn = exports.lightSpeedOut = exports.lightSpeedIn = exports.flipOutY = exports.flipOutX = exports.flipInY = exports.flipInX = exports.flip = exports.fadeOutUpBig = exports.fadeOutUp = exports.fadeOutRightBig = exports.fadeOutRight = exports.fadeOutLeftBig = exports.fadeOutLeft = exports.fadeOutDownBig = exports.fadeOutDown = exports.fadeOut = exports.fadeInUpBig = exports.fadeInUp = exports.fadeInRightBig = exports.fadeInRight = exports.fadeInLeftBig = exports.fadeInLeft = exports.fadeInDownBig = exports.fadeInDown = exports.fadeIn = exports.bounceOutUp = exports.bounceOutRight = exports.bounceOutLeft = exports.bounceOutDown = exports.bounceOut = exports.bounceInUp = exports.bounceInRight = exports.bounceInLeft = exports.bounceInDown = exports.bounceIn = exports.tada = exports.swing = exports.shake = exports.rubberBand = exports.headShake = exports.wobble = exports.jello = exports.pulse = exports.flash = exports.bounce = exports.merge = undefined;

var _merge2 = require('./merge');

var _merge3 = _interopRequireDefault(_merge2);

var _bounce2 = require('./bounce');

var _bounce3 = _interopRequireDefault(_bounce2);

var _flash2 = require('./flash');

var _flash3 = _interopRequireDefault(_flash2);

var _pulse2 = require('./pulse');

var _pulse3 = _interopRequireDefault(_pulse2);

var _jello2 = require('./jello');

var _jello3 = _interopRequireDefault(_jello2);

var _wobble2 = require('./wobble');

var _wobble3 = _interopRequireDefault(_wobble2);

var _headShake2 = require('./head-shake');

var _headShake3 = _interopRequireDefault(_headShake2);

var _rubberBand2 = require('./rubber-band');

var _rubberBand3 = _interopRequireDefault(_rubberBand2);

var _shake2 = require('./shake');

var _shake3 = _interopRequireDefault(_shake2);

var _swing2 = require('./swing');

var _swing3 = _interopRequireDefault(_swing2);

var _tada2 = require('./tada');

var _tada3 = _interopRequireDefault(_tada2);

var _bounceIn2 = require('./bounce-in');

var _bounceIn3 = _interopRequireDefault(_bounceIn2);

var _bounceInDown2 = require('./bounce-in-down');

var _bounceInDown3 = _interopRequireDefault(_bounceInDown2);

var _bounceInLeft2 = require('./bounce-in-left');

var _bounceInLeft3 = _interopRequireDefault(_bounceInLeft2);

var _bounceInRight2 = require('./bounce-in-right');

var _bounceInRight3 = _interopRequireDefault(_bounceInRight2);

var _bounceInUp2 = require('./bounce-in-up');

var _bounceInUp3 = _interopRequireDefault(_bounceInUp2);

var _bounceOut2 = require('./bounce-out');

var _bounceOut3 = _interopRequireDefault(_bounceOut2);

var _bounceOutDown2 = require('./bounce-out-down');

var _bounceOutDown3 = _interopRequireDefault(_bounceOutDown2);

var _bounceOutLeft2 = require('./bounce-out-left');

var _bounceOutLeft3 = _interopRequireDefault(_bounceOutLeft2);

var _bounceOutRight2 = require('./bounce-out-right');

var _bounceOutRight3 = _interopRequireDefault(_bounceOutRight2);

var _bounceOutUp2 = require('./bounce-out-up');

var _bounceOutUp3 = _interopRequireDefault(_bounceOutUp2);

var _fadeIn2 = require('./fade-in');

var _fadeIn3 = _interopRequireDefault(_fadeIn2);

var _fadeInDown2 = require('./fade-in-down');

var _fadeInDown3 = _interopRequireDefault(_fadeInDown2);

var _fadeInDownBig2 = require('./fade-in-down-big');

var _fadeInDownBig3 = _interopRequireDefault(_fadeInDownBig2);

var _fadeInLeft2 = require('./fade-in-left');

var _fadeInLeft3 = _interopRequireDefault(_fadeInLeft2);

var _fadeInLeftBig2 = require('./fade-in-left-big');

var _fadeInLeftBig3 = _interopRequireDefault(_fadeInLeftBig2);

var _fadeInRight2 = require('./fade-in-right');

var _fadeInRight3 = _interopRequireDefault(_fadeInRight2);

var _fadeInRightBig2 = require('./fade-in-right-big');

var _fadeInRightBig3 = _interopRequireDefault(_fadeInRightBig2);

var _fadeInUp2 = require('./fade-in-up');

var _fadeInUp3 = _interopRequireDefault(_fadeInUp2);

var _fadeInUpBig2 = require('./fade-in-up-big');

var _fadeInUpBig3 = _interopRequireDefault(_fadeInUpBig2);

var _fadeOut2 = require('./fade-out');

var _fadeOut3 = _interopRequireDefault(_fadeOut2);

var _fadeOutDown2 = require('./fade-out-down');

var _fadeOutDown3 = _interopRequireDefault(_fadeOutDown2);

var _fadeOutDownBig2 = require('./fade-out-down-big');

var _fadeOutDownBig3 = _interopRequireDefault(_fadeOutDownBig2);

var _fadeOutLeft2 = require('./fade-out-left');

var _fadeOutLeft3 = _interopRequireDefault(_fadeOutLeft2);

var _fadeOutLeftBig2 = require('./fade-out-left-big');

var _fadeOutLeftBig3 = _interopRequireDefault(_fadeOutLeftBig2);

var _fadeOutRight2 = require('./fade-out-right');

var _fadeOutRight3 = _interopRequireDefault(_fadeOutRight2);

var _fadeOutRightBig2 = require('./fade-out-right-big');

var _fadeOutRightBig3 = _interopRequireDefault(_fadeOutRightBig2);

var _fadeOutUp2 = require('./fade-out-up');

var _fadeOutUp3 = _interopRequireDefault(_fadeOutUp2);

var _fadeOutUpBig2 = require('./fade-out-up-big');

var _fadeOutUpBig3 = _interopRequireDefault(_fadeOutUpBig2);

var _flip2 = require('./flip');

var _flip3 = _interopRequireDefault(_flip2);

var _flipInX2 = require('./flip-in-x');

var _flipInX3 = _interopRequireDefault(_flipInX2);

var _flipInY2 = require('./flip-in-y');

var _flipInY3 = _interopRequireDefault(_flipInY2);

var _flipOutX2 = require('./flip-out-x');

var _flipOutX3 = _interopRequireDefault(_flipOutX2);

var _flipOutY2 = require('./flip-out-y');

var _flipOutY3 = _interopRequireDefault(_flipOutY2);

var _lightSpeedIn2 = require('./light-speed-in');

var _lightSpeedIn3 = _interopRequireDefault(_lightSpeedIn2);

var _lightSpeedOut2 = require('./light-speed-out');

var _lightSpeedOut3 = _interopRequireDefault(_lightSpeedOut2);

var _rotateIn2 = require('./rotate-in');

var _rotateIn3 = _interopRequireDefault(_rotateIn2);

var _rotateInDownLeft2 = require('./rotate-in-down-left');

var _rotateInDownLeft3 = _interopRequireDefault(_rotateInDownLeft2);

var _rotateInDownRight2 = require('./rotate-in-down-right');

var _rotateInDownRight3 = _interopRequireDefault(_rotateInDownRight2);

var _rotateInUpLeft2 = require('./rotate-in-up-left');

var _rotateInUpLeft3 = _interopRequireDefault(_rotateInUpLeft2);

var _rotateInUpRight2 = require('./rotate-in-up-right');

var _rotateInUpRight3 = _interopRequireDefault(_rotateInUpRight2);

var _rotateOut2 = require('./rotate-out');

var _rotateOut3 = _interopRequireDefault(_rotateOut2);

var _rotateOutDownLeft2 = require('./rotate-out-down-left');

var _rotateOutDownLeft3 = _interopRequireDefault(_rotateOutDownLeft2);

var _rotateOutDownRight2 = require('./rotate-out-down-right');

var _rotateOutDownRight3 = _interopRequireDefault(_rotateOutDownRight2);

var _rotateOutUpLeft2 = require('./rotate-out-up-left');

var _rotateOutUpLeft3 = _interopRequireDefault(_rotateOutUpLeft2);

var _rotateOutUpRight2 = require('./rotate-out-up-right');

var _rotateOutUpRight3 = _interopRequireDefault(_rotateOutUpRight2);

var _slideInDown2 = require('./slide-in-down');

var _slideInDown3 = _interopRequireDefault(_slideInDown2);

var _slideInLeft2 = require('./slide-in-left');

var _slideInLeft3 = _interopRequireDefault(_slideInLeft2);

var _slideInRight2 = require('./slide-in-right');

var _slideInRight3 = _interopRequireDefault(_slideInRight2);

var _slideInUp2 = require('./slide-in-up');

var _slideInUp3 = _interopRequireDefault(_slideInUp2);

var _slideOutDown2 = require('./slide-out-down');

var _slideOutDown3 = _interopRequireDefault(_slideOutDown2);

var _slideOutLeft2 = require('./slide-out-left');

var _slideOutLeft3 = _interopRequireDefault(_slideOutLeft2);

var _slideOutRight2 = require('./slide-out-right');

var _slideOutRight3 = _interopRequireDefault(_slideOutRight2);

var _slideOutUp2 = require('./slide-out-up');

var _slideOutUp3 = _interopRequireDefault(_slideOutUp2);

var _hinge2 = require('./hinge');

var _hinge3 = _interopRequireDefault(_hinge2);

var _rollIn2 = require('./roll-in');

var _rollIn3 = _interopRequireDefault(_rollIn2);

var _rollOut2 = require('./roll-out');

var _rollOut3 = _interopRequireDefault(_rollOut2);

var _zoomIn2 = require('./zoom-in');

var _zoomIn3 = _interopRequireDefault(_zoomIn2);

var _zoomInDown2 = require('./zoom-in-down');

var _zoomInDown3 = _interopRequireDefault(_zoomInDown2);

var _zoomInLeft2 = require('./zoom-in-left');

var _zoomInLeft3 = _interopRequireDefault(_zoomInLeft2);

var _zoomInRight2 = require('./zoom-in-right');

var _zoomInRight3 = _interopRequireDefault(_zoomInRight2);

var _zoomInUp2 = require('./zoom-in-up');

var _zoomInUp3 = _interopRequireDefault(_zoomInUp2);

var _zoomOut2 = require('./zoom-out');

var _zoomOut3 = _interopRequireDefault(_zoomOut2);

var _zoomOutDown2 = require('./zoom-out-down');

var _zoomOutDown3 = _interopRequireDefault(_zoomOutDown2);

var _zoomOutLeft2 = require('./zoom-out-left');

var _zoomOutLeft3 = _interopRequireDefault(_zoomOutLeft2);

var _zoomOutRight2 = require('./zoom-out-right');

var _zoomOutRight3 = _interopRequireDefault(_zoomOutRight2);

var _zoomOutUp2 = require('./zoom-out-up');

var _zoomOutUp3 = _interopRequireDefault(_zoomOutUp2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.merge = _merge3.default;

/* Attention seekers */
/* Fun stuff */

exports.bounce = _bounce3.default;
exports.flash = _flash3.default;
exports.pulse = _pulse3.default;
exports.jello = _jello3.default;
exports.wobble = _wobble3.default;
exports.headShake = _headShake3.default;
exports.rubberBand = _rubberBand3.default;
exports.shake = _shake3.default;
exports.swing = _swing3.default;
exports.tada = _tada3.default;

/* Bouncing entrances */

exports.bounceIn = _bounceIn3.default;
exports.bounceInDown = _bounceInDown3.default;
exports.bounceInLeft = _bounceInLeft3.default;
exports.bounceInRight = _bounceInRight3.default;
exports.bounceInUp = _bounceInUp3.default;

/* Bouncing  exits */

exports.bounceOut = _bounceOut3.default;
exports.bounceOutDown = _bounceOutDown3.default;
exports.bounceOutLeft = _bounceOutLeft3.default;
exports.bounceOutRight = _bounceOutRight3.default;
exports.bounceOutUp = _bounceOutUp3.default;

/* Fading entrances */

exports.fadeIn = _fadeIn3.default;
exports.fadeInDown = _fadeInDown3.default;
exports.fadeInDownBig = _fadeInDownBig3.default;
exports.fadeInLeft = _fadeInLeft3.default;
exports.fadeInLeftBig = _fadeInLeftBig3.default;
exports.fadeInRight = _fadeInRight3.default;
exports.fadeInRightBig = _fadeInRightBig3.default;
exports.fadeInUp = _fadeInUp3.default;
exports.fadeInUpBig = _fadeInUpBig3.default;

/* Fading exits */

exports.fadeOut = _fadeOut3.default;
exports.fadeOutDown = _fadeOutDown3.default;
exports.fadeOutDownBig = _fadeOutDownBig3.default;
exports.fadeOutLeft = _fadeOutLeft3.default;
exports.fadeOutLeftBig = _fadeOutLeftBig3.default;
exports.fadeOutRight = _fadeOutRight3.default;
exports.fadeOutRightBig = _fadeOutRightBig3.default;
exports.fadeOutUp = _fadeOutUp3.default;
exports.fadeOutUpBig = _fadeOutUpBig3.default;

/* Flippers */

exports.flip = _flip3.default;
exports.flipInX = _flipInX3.default;
exports.flipInY = _flipInY3.default;
exports.flipOutX = _flipOutX3.default;
exports.flipOutY = _flipOutY3.default;

/* Lightspeed */

exports.lightSpeedIn = _lightSpeedIn3.default;
exports.lightSpeedOut = _lightSpeedOut3.default;

/* Rotating entrances */

exports.rotateIn = _rotateIn3.default;
exports.rotateInDownLeft = _rotateInDownLeft3.default;
exports.rotateInDownRight = _rotateInDownRight3.default;
exports.rotateInUpLeft = _rotateInUpLeft3.default;
exports.rotateInUpRight = _rotateInUpRight3.default;

/* Rotation exits */

exports.rotateOut = _rotateOut3.default;
exports.rotateOutDownLeft = _rotateOutDownLeft3.default;
exports.rotateOutDownRight = _rotateOutDownRight3.default;
exports.rotateOutUpLeft = _rotateOutUpLeft3.default;
exports.rotateOutUpRight = _rotateOutUpRight3.default;

/* Sliding entrances */

exports.slideInDown = _slideInDown3.default;
exports.slideInLeft = _slideInLeft3.default;
exports.slideInRight = _slideInRight3.default;
exports.slideInUp = _slideInUp3.default;

/* Sliding exits */

exports.slideOutDown = _slideOutDown3.default;
exports.slideOutLeft = _slideOutLeft3.default;
exports.slideOutRight = _slideOutRight3.default;
exports.slideOutUp = _slideOutUp3.default;

/* Specials */

exports.hinge = _hinge3.default;
exports.rollIn = _rollIn3.default;
exports.rollOut = _rollOut3.default;

/* Zooming entrances */

exports.zoomIn = _zoomIn3.default;
exports.zoomInDown = _zoomInDown3.default;
exports.zoomInLeft = _zoomInLeft3.default;
exports.zoomInRight = _zoomInRight3.default;
exports.zoomInUp = _zoomInUp3.default;

/* Zooming exits */

exports.zoomOut = _zoomOut3.default;
exports.zoomOutDown = _zoomOutDown3.default;
exports.zoomOutLeft = _zoomOutLeft3.default;
exports.zoomOutRight = _zoomOutRight3.default;
exports.zoomOutUp = _zoomOutUp3.default;