'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var pulse = {
  from: {
    transform: (0, _utils.scale3d)(1, 1, 1)
  },
  '50%': {
    transform: (0, _utils.scale3d)(1.05, 1.05, 1.05)
  },
  to: {
    transform: (0, _utils.scale3d)(1, 1, 1)
  }
};
exports.default = pulse;