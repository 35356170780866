'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var perspectiveAndRotate = (0, _utils.compose)(_utils.perspective, _utils.rotate3d);


var flipOutY = {
  from: {
    transform: (0, _utils.perspective)('400px')
  },
  '30%': {
    transform: perspectiveAndRotate('400px', [0, 1, 0, -15]),
    opacity: 1
  },
  to: {
    transform: perspectiveAndRotate('400px', [0, 1, 0, 90]),
    opacity: 0
  }
};

exports.default = flipOutY;