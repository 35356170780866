"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    animation-name: ", ";\n    animation-duration: ", "ms;\n    animation-iteration-count: ", ";\n    display: 'inline-block';\n    transform-origin: ", ";\n\n    &", " {\n      animation-name: ", ";\n      animation-play-state: ", ";\n      animation: ", ";\n    }\n\n    animation-play-state: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["", ""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var Shake = function Shake(_ref) {
  var _ref$h = _ref.h,
      h = _ref$h === void 0 ? 5 : _ref$h,
      _ref$v = _ref.v,
      v = _ref$v === void 0 ? 5 : _ref$v,
      _ref$r = _ref.r,
      r = _ref$r === void 0 ? 3 : _ref$r,
      _ref$dur = _ref.dur,
      dur = _ref$dur === void 0 ? 300 : _ref$dur,
      _ref$q = _ref.q,
      q = _ref$q === void 0 ? 'infinite' : _ref$q,
      _ref$tf = _ref.tf,
      tf = _ref$tf === void 0 ? 'ease-in-out' : _ref$tf,
      _ref$int = _ref["int"],
      _int = _ref$int === void 0 ? 10 : _ref$int,
      _ref$max = _ref.max,
      max = _ref$max === void 0 ? 100 : _ref$max,
      _ref$orig = _ref.orig,
      orig = _ref$orig === void 0 ? 'center center' : _ref$orig,
      _ref$fixed = _ref.fixed,
      fixed = _ref$fixed === void 0 ? false : _ref$fixed,
      _ref$freez = _ref.freez,
      freez = _ref$freez === void 0 ? false : _ref$freez,
      _ref$active = _ref.active,
      active = _ref$active === void 0 ? true : _ref$active,
      _ref$trigger = _ref.trigger,
      trigger = _ref$trigger === void 0 ? ':hover' : _ref$trigger,
      _ref$fixedStop = _ref.fixedStop,
      fixedStop = _ref$fixedStop === void 0 ? false : _ref$fixedStop,
      _ref$elem = _ref.elem,
      elem = _ref$elem === void 0 ? 'div' : _ref$elem,
      props = _objectWithoutProperties(_ref, ["h", "v", "r", "dur", "q", "tf", "int", "max", "orig", "fixed", "freez", "active", "trigger", "fixedStop", "elem"]);

  var random = function random(max) {
    var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return Math.random() * (max - min) - max / 2;
  };

  var doKeyframes = function doKeyframes() {
    // el objecto que iremos llenando
    var kf = {};
    var init = 'translate(0,0) rotate(0)'; // loop con intervalos basados en `int`

    for (var st = _int; st <= max; st += _int) {
      // Numeros aleatorios en cada keyframe
      var x = random(h);
      var y = random(v);
      var rot = random(r);
      kf["".concat(st, "%")] = {
        transform: "translate(".concat(x, "px, ").concat(y, "px) rotate(").concat(rot, "deg)")
      };
    } // Init de las transformaciones en 0% y 100%


    kf["0%"] = kf["".concat(Math.min(max, 100), "%")] = {
      transform: init
    };
    return kf;
  }; // Creamos los `@keyframes`


  var kf = doKeyframes();

  var toString = function toString(obj) {
    return Object.keys(obj).reduce(function (acc, next) {
      return "".concat(acc, "\n\t\t\t").concat(next, " {\n\t\t\t\ttransform: ").concat(obj[next].transform, "\n\t\t\t}");
    }, '');
  };

  var shakeKeyframes = (0, _styledComponents.keyframes)(_templateObject(), toString(kf));
  var shouldShakeDefault = fixed || !fixed && freez;
  var shouldShakeWhenTriggered = !fixed && !freez;

  var ShakeComp = _styledComponents["default"][elem](_templateObject2(), shouldShakeDefault && shakeKeyframes, dur, q, orig, trigger, shouldShakeWhenTriggered && shakeKeyframes, freez && (!fixed ? 'running' : 'paused'), fixed && fixedStop && 'initial', active ? freez && !fixed ? 'paused' : 'running' : 'paused');

  return _react["default"].createElement(ShakeComp, props, props.children);
};

var _default = Shake;
exports["default"] = _default;