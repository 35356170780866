'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var translateAndRotate = (0, _utils.compose)(_utils.translate3d, _utils.rotate3d);


var noWobble = {
  transform: 'none'
};

var wobble = {
  from: noWobble,
  '15%': {
    transform: translateAndRotate(['-25%', 0, 0], [0, 0, 1, -5])
  },
  '30%': {
    transform: translateAndRotate(['20%', 0, 0], [0, 0, 1, -5])
  },
  '45%': {
    transform: translateAndRotate(['-15%', 0, 0], [0, 0, 1, -3])
  },
  '60%': {
    transform: translateAndRotate(['10%', 0, 0], [0, 0, 1, 2])
  },
  '75%': {
    transform: translateAndRotate(['-5%', 0, 0], [0, 0, 1, -1])
  },
  to: noWobble
};

exports.default = wobble;