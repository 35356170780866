'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var slideOutLeft = {
  from: {
    transform: (0, _utils.translate3d)(0, 0, 0)
  },
  to: {
    visibility: 'hidden',
    transform: (0, _utils.translate3d)('-100%', 0, 0)
  }
};
exports.default = slideOutLeft;