'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var perspectiveAndRotate = (0, _utils.compose)(_utils.perspective, _utils.rotate3d);


var flipInX = {
  from: {
    animationTimingFunction: 'ease-out',
    transform: perspectiveAndRotate('400px', [1, 0, 0, 90]),
    opacity: 0
  },
  '40%': {
    animationTimingFunction: 'ease-in',
    transform: perspectiveAndRotate('400px', [1, 0, 0, -20])
  },
  '60%': {
    transform: perspectiveAndRotate('400px', [1, 0, 0, 10])
  },
  '80%': {
    transform: perspectiveAndRotate('400px', [1, 0, 0, -5])
  },
  to: {
    transform: (0, _utils.perspective)('400px')
  }
};

exports.default = flipInX;