'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var noShake = {
  transform: (0, _utils.translate3d)(0, 0, 0)
};


var downShake = {
  transform: (0, _utils.translate3d)('-10px', 0, 0)
};

var upShake = {
  transform: (0, _utils.translate3d)('10px', 0, 0)
};

var shake = {
  from: noShake,
  '10%': downShake,
  '20%': upShake,
  '30%': downShake,
  '40%': upShake,
  '50%': downShake,
  '60%': upShake,
  '70%': downShake,
  '80%': upShake,
  '90%': downShake,
  to: noShake
};

exports.default = shake;