"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Shake", {
  enumerable: true,
  get: function get() {
    return _Shake["default"];
  }
});
Object.defineProperty(exports, "Shaking", {
  enumerable: true,
  get: function get() {
    return _Shaking["default"];
  }
});
Object.defineProperty(exports, "shakes", {
  enumerable: true,
  get: function get() {
    return _shakes["default"];
  }
});
exports["default"] = exports.ShakeCrazy = exports.ShakeRotate = exports.ShakeVertical = exports.ShakeHorizontal = exports.ShakeHard = exports.ShakeSlow = exports.ShakeLittle = void 0;

var _react = _interopRequireDefault(require("react"));

var _Shake = _interopRequireDefault(require("./Shake"));

var _Shaking = _interopRequireDefault(require("./Shaking"));

var _shakes = _interopRequireDefault(require("./shakes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ShakeLittle = function ShakeLittle(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "little"
  }));
};

exports.ShakeLittle = ShakeLittle;

var ShakeSlow = function ShakeSlow(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "slow"
  }));
};

exports.ShakeSlow = ShakeSlow;

var ShakeHard = function ShakeHard(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "hard"
  }));
};

exports.ShakeHard = ShakeHard;

var ShakeHorizontal = function ShakeHorizontal(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "horizontal"
  }));
};

exports.ShakeHorizontal = ShakeHorizontal;

var ShakeVertical = function ShakeVertical(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "vertical"
  }));
};

exports.ShakeVertical = ShakeVertical;

var ShakeRotate = function ShakeRotate(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "rotate"
  }));
};

exports.ShakeRotate = ShakeRotate;

var ShakeCrazy = function ShakeCrazy(props) {
  return _react["default"].createElement(_Shaking["default"], _extends({}, props, {
    type: "crazy"
  }));
};

exports.ShakeCrazy = ShakeCrazy;
var _default = {
  Shake: _Shake["default"],
  Shaking: _Shaking["default"],
  shakes: _shakes["default"],
  ShakeLittle: ShakeLittle,
  ShakeSlow: ShakeSlow,
  ShakeHard: ShakeHard,
  ShakeHorizontal: ShakeHorizontal,
  ShakeVertical: ShakeVertical,
  ShakeRotate: ShakeRotate,
  ShakeCrazy: ShakeCrazy
};
exports["default"] = _default;