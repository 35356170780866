'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _utils = require('./utils');

var translateAndSkew = (0, _utils.compose)(_utils.translate3d, _utils.skewX);

var easeIn = {
  animationTimingFunction: 'ease-out'
};

var lightSpeedOut = {
  from: _extends({}, easeIn, {
    opacity: 1
  }),
  to: _extends({}, easeIn, {
    transform: translateAndSkew(['100%', 0, 0], 30),
    opacity: 0
  })
};

exports.default = lightSpeedOut;