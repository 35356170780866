'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var translateAndRotate = (0, _utils.compose)(_utils.translateX, _utils.rotateY);


var noShake = {
  transform: (0, _utils.translateX)(0)
};

var headShake = {
  '0%': noShake,
  '6.5%': {
    transform: translateAndRotate('-6px', '-9deg')
  },
  '18.5%': {
    transform: translateAndRotate('5px', '7deg')
  },
  '31.5%': {
    transform: translateAndRotate('-3px', '-5deg')
  },
  '43.5%': {
    transform: translateAndRotate('2px', '3deg')
  },
  '50%': noShake
};

exports.default = headShake;