'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var fadeOutRightBig = {
  from: {
    opacity: 1
  },
  to: {
    opacity: 0,
    transform: (0, _utils.translate3d)('2000px', 0, 0)
  }
};
exports.default = fadeOutRightBig;