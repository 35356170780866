'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var noRubberBanding = {
  transform: (0, _utils.scale3d)(1, 1, 1)
};


var rubberBand = {
  from: noRubberBanding,
  '30%': {
    transform: (0, _utils.scale3d)(1.25, 0.75, 1)
  },
  '40%': {
    transform: (0, _utils.scale3d)(0.75, 1.25, 1)
  },
  '50%': {
    transform: (0, _utils.scale3d)(1.15, 0.85, 1)
  },
  '65%': {
    transform: (0, _utils.scale3d)(0.95, 1.05, 1)
  },
  '75%': {
    transform: (0, _utils.scale3d)(1.05, 0.95, 1)
  },
  to: noRubberBanding
};

exports.default = rubberBand;