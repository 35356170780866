'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('./utils');

var scale3dAndTranslate = (0, _utils.compose)(_utils.scale3d, _utils.translate3d);

var scaleAndTranslate = (0, _utils.compose)(_utils.scale, _utils.translate3d);

var zoomOutRight = {
  '40%': {
    opacity: 1,
    transform: scale3dAndTranslate([0.475, 0.475, 0.475], ['-42px', 0, 0])
  },
  to: {
    opacity: 0,
    transform: scaleAndTranslate(0.1, ['2000px', 0, 0]),
    transformOrigin: 'right center'
  }
};

exports.default = zoomOutRight;