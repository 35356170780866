'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


var fadeOut = {
  from: {
    opacity: 1
  },
  to: {
    opacity: 0
  }
};
exports.default = fadeOut;