'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _utils = require('./utils');

var translateAndSkew = (0, _utils.compose)(_utils.translate3d, _utils.skewX);

var easeIn = {
  animationTimingFunction: 'ease-out'
};

var lightSpeedIn = {
  from: _extends({}, easeIn, {
    opacity: 0,
    transform: translateAndSkew(['100%', 0, 0], -30)
  }),
  '60%': _extends({}, easeIn, {
    opacity: 1,
    transform: (0, _utils.skewX)(20)
  }),
  '80%': _extends({}, easeIn, {
    opacity: 1,
    transform: (0, _utils.skewX)(-5)
  }),
  to: _extends({}, easeIn, {
    transform: 'none',
    opacity: 1
  })
};

exports.default = lightSpeedIn;